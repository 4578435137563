import { WebViewContext } from "@frontapp/plugin-sdk/dist/webViewSdkTypes";
import React, { useEffect, useState } from "react";
import "./App.less";
import { useFrontContext } from "./helpers/frontContext";
import { UserOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import { getSuggestions, getSummary } from "./helpers/api";
import Front, {
  ApplicationConversationId,
  ApplicationDraftId,
  ApplicationMessageId,
} from "@frontapp/plugin-sdk";
import { htmlToText } from "html-to-text";

const { Paragraph } = Typography;

interface ConvoContext {
  [convoid: string]: {
    convoSummary?: string;
    lastMessages?: string;
    suggestions?: string[];
    lmid?: ApplicationMessageId;
    draftID?: ApplicationDraftId;
    loading?: boolean;
  };
}

function App() {
  const context: WebViewContext = useFrontContext();

  const [convoContext, setConvoContext] = useState<ConvoContext>({});

  const [sValue, setSValue] = useState();

  const [convoID, setconvoID] = useState<ApplicationConversationId>();

  useEffect(() => {
    if (!context) return;
    switch (context.type) {
      case "singleConversation":
        let cid = context.conversation.id;
        if (convoID !== cid) {
          setSValue(undefined);
          if (!convoContext[cid]?.convoSummary)
            context.listMessages().then((msgs) => {
              let convo = msgs.results
                .map((m) => {
                  return `${
                    m.status === "inbound" ? "\nU: " : "\nA: "
                  }${htmlToText(
                    m.content?.body.replace(/<blockquote .*/g, "") || ""
                  )
                    .replace(/(\r\n|\r|\n){2,}/g, "$1\n")
                    .trim()}`;
                })
                .join(";");
              convo = `The name of the customer is  ${context.conversation.recipient?.name}; ${convo}`;
              let lm = msgs.results.filter((m) => m.status === "inbound");
              let last_message = lm[lm.length - 1];
              getSummary(convo)
                .then((response) => {
                  let cc = convoContext[cid] || {};
                  cc.lastMessages = convo;
                  cc.convoSummary = response;
                  cc.lmid = last_message.id;
                  convoContext[cid] = cc;
                  setConvoContext((convoContext) => ({
                    ...convoContext,
                    [cid]: cc,
                  }));
                })
                .catch((e) => {
                  console.error(e);
                });
            });
          setconvoID(cid);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  if (!context)
    return (
      <div className="App">
        <p>Waiting to connect to the Front context.</p>
      </div>
    );

  switch (context.type) {
    case "noConversation":
      return (
        <div className="App">
          <p>
            No conversation selected. Select a conversation to use this plugin.
          </p>
        </div>
      );
    case "singleConversation":
      if (!convoID) return <>Loading convo...</>;
      let cc = convoContext[convoID];
      if (!cc) cc = {};
      let suggestions = cc.suggestions;
      let lmid = cc.lmid;
      let draftID = context.conversation.draftId;

      return (
        <Row
          align={"middle"}
          justify={"start"}
          className="single-convo-container">
          <Col span={24}>
            <Descriptions
              title={
                <>
                  <UserOutlined rev={undefined} /> User Info
                </>
              }>
              <Descriptions.Item label="UserName">
                {context.conversation.recipient?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Handle">
                {context.conversation.recipient?.handle}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Alert
              className="summary"
              message={`Summary of conversation with ${context.conversation.recipient?.name}`}
              description={
                !convoContext[convoID] ||
                !convoContext[convoID].convoSummary ? (
                  "Generating..."
                ) : (
                  <Paragraph
                    ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
                    {convoContext[convoID].convoSummary
                      ?.replaceAll("A:", "Gradly:")
                      .replaceAll(
                        "U:",
                        context.conversation.recipient?.name?.split(" ")[0] +
                          ":" || "U:"
                      )}
                  </Paragraph>
                )
              }
              type="warning"
              closable
            />
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              disabled={
                !convoContext[convoID]?.convoSummary ||
                convoContext[convoID].convoSummary === "Generating..."
              }
              loading={convoContext[convoID]?.loading}
              onClick={async () => {
                let cid = convoID.slice();

                setConvoContext((convoContext) => {
                  return {
                    ...convoContext,
                    [convoID]: {
                      ...convoContext[convoID],
                      loading: true,
                    },
                  };
                });
                context
                  .listMessages()
                  .then(async (msgs) => {
                    let lm = msgs.results.filter((m) => m.status === "inbound");
                    let last_message = lm[lm.length - 1];
                    if (
                      last_message?.content?.body &&
                      convoContext[cid].convoSummary
                    ) {
                      try {
                        let suggestions = await getSuggestions(
                          convoContext[cid].convoSummary || "",
                          last_message?.content.body
                        );
                        cc.suggestions = suggestions;
                        setConvoContext((convoContext) => {
                          return {
                            ...convoContext,
                            [cid]: {
                              ...convoContext[cid],
                              suggestions: suggestions.suggestions,
                            },
                          };
                        });
                      } catch {}
                    }
                    setConvoContext((convoContext) => {
                      return {
                        ...convoContext,
                        [cid]: {
                          ...convoContext[cid],
                          loading: false,
                        },
                      };
                    });
                  })
                  .catch(() => {
                    setConvoContext((convoContext) => {
                      return {
                        ...convoContext,
                        [cid]: {
                          ...convoContext[cid],
                          loading: false,
                        },
                      };
                    });
                  });
              }}>
              Generate replies
            </Button>
          </Col>
          <Col span={24}>
            {suggestions && suggestions.length > 0 && (
              <Row className="suggestions">
                <Col style={{ marginBottom: 24 }} span={24}>
                  Select any response to autofill
                </Col>
                <Col span={24}>
                  <Radio.Group
                    onChange={(e) => {
                      setSValue(e.target.value);
                      if (lmid) {
                        if (draftID) {
                          Front.updateDraft(draftID, {
                            updateMode: "replace",
                            content: {
                              body:
                                (suggestions && suggestions[e.target.value]) ||
                                "",
                              type: "text",
                            },
                          });
                        } else {
                          Front.createDraft({
                            content: {
                              body:
                                (suggestions && suggestions[e.target.value]) ||
                                "",
                              type: "text",
                            },
                            replyOptions: {
                              type: "reply",
                              originalMessageId: lmid,
                            },
                          });
                        }
                      }
                    }}
                    value={sValue}
                    defaultValue={undefined}>
                    <Space direction="vertical">
                      {suggestions.map((s, i) => {
                        return (
                          <Radio
                            key={`r-${i}`}
                            className="suggestion"
                            value={i}>
                            {s}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      );
    case "multiConversations":
      return (
        <div className="App">
          <p>
            Multiple conversations selected. Select only one conversation to use
            this plugin.
          </p>
        </div>
      );
    default:
      console.error(`Unsupported context type: ${context.type}`);
      return null;
  }
}

export default App;
