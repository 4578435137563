import axios from "axios";
const API_URL = process.env.REACT_APP_MIND_API_URL;
export const controller = new AbortController();
export const getSummary = async (convo: string) => {
  // return "The conversation is between Ishita Agrawal and the agent. The agent informed Ishita that her home country residential address has been updated and asked if she needs any further assistance. Ishita confirmed that she has registered and filed for a waiver. Later, Ishita asked if USC will refund the health fee for the spring semester as they did in the fall semester. The agent explained that USC will not charge anything for insurance in the spring semester since Ishita completed the annual waiver in the fall semester. Hence, instead of a refund, she will pay $1468 less on her spring tuition bill than she would otherwise pay. Later, Ishita asked if she can still pay overdue insurance fees without any additional charges, to which the agent confirmed that there won't be any additional charges. Finally, Ishita shared a screenshot of her payment confirmation email with the agent who confirmed receiving it and assured her that there is nothing else pending from her side.";
  const text = await axios.post(
    `${API_URL}/summarize_convo`,
    {
      convo,
    },
    { signal: controller.signal }
  );
  return text.data.response;
};

export const getSuggestions = async (summary: string, last_message: string) => {
  // return {
  //   suggestions: [
  //     "Thank you for your confirmation, Ishita. There is indeed nothing else pending from your side. If you have any further questions or concerns, feel free to reach out to us.",
  //     "Ishita, I can confirm that there is nothing else pending from your side. If you need any further assistance, please don't hesitate to contact us.",
  //     "You're all set, Ishita! There's nothing else pending from your side. If you have any other questions or need assistance in the future, please feel free to reach out.",
  //   ],
  // };
  const text = await axios.post(
    `${API_URL}/suggestions`,
    {
      summary,
      last_message,
    },

    { signal: controller.signal }
  );
  return text.data;
};
